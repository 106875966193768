/*!
 * Bootstrap v3.0.0
 *
 * Copyright 2013 Twitter, Inc
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Designed and built with all the love in the world by @mdo and @fat.
 */

// Core variables and mixins
@import "../../../node_modules/bootstrap-less/less/mixins.less";

// Reset
@import "../../../node_modules/bootstrap-less/less/normalize.less";
@import "../../../node_modules/bootstrap-less/less/print.less";

// Core CSS
@import "../../../node_modules/bootstrap-less/less/scaffolding.less";
@import "../../../node_modules/bootstrap-less/less/type.less";
// @import "../../../node_modules/bootstrap-less/less/code.less";
@import "../../../node_modules/bootstrap-less/less/grid.less";
@import "../../../node_modules/bootstrap-less/less/tables.less";
@import "../../../node_modules/bootstrap-less/less/forms.less";
//@import "../../../node_modules/bootstrap-less/less/buttons.less";

// Components
@import "../../../node_modules/bootstrap-less/less/component-animations.less";
// @import "../../../node_modules/bootstrap-less/less/glyphicons.less";
@import "../../../node_modules/bootstrap-less/less/dropdowns.less";
// @import "../../../node_modules/bootstrap-less/less/button-groups.less";
@import "../../../node_modules/bootstrap-less/less/input-groups.less";
@import "../../../node_modules/bootstrap-less/less/navs.less";
@import "../../../node_modules/bootstrap-less/less/navbar.less";
// @import "../../../node_modules/bootstrap-less/less/breadcrumbs.less";
// @import "../../../node_modules/bootstrap-less/less/pagination.less";
// @import "../../../node_modules/bootstrap-less/less/pager.less";
// @import "../../../node_modules/bootstrap-less/less/labels.less";
// @import "../../../node_modules/bootstrap-less/less/badges.less";
// @import "../../../node_modules/bootstrap-less/less/jumbotron.less";
// @import "../../../node_modules/bootstrap-less/less/thumbnails.less";
// @import "../../../node_modules/bootstrap-less/less/alerts.less";
@import "../../../node_modules/bootstrap-less/less/progress-bars.less";
// @import "../../../node_modules/bootstrap-less/less/media.less";
// @import "../../../node_modules/bootstrap-less/less/list-group.less";
// @import "../../../node_modules/bootstrap-less/less/panels.less";
// @import "../../../node_modules/bootstrap-less/less/wells.less";
// @import "../../../node_modules/bootstrap-less/less/close.less";

// Components w/ JavaScript
@import "../../../node_modules/bootstrap-less/less/modals.less";
@import "../../../node_modules/bootstrap-less/less/tooltip.less";
// @import "../../../node_modules/bootstrap-less/less/popovers.less";
// @import "../../../node_modules/bootstrap-less/less/carousel.less";

// Utility classes
@import "../../../node_modules/bootstrap-less/less/utilities.less";
@import "../../../node_modules/bootstrap-less/less/responsive-utilities.less";

.modal-dialog {
  padding: 0;
  margin: 0 auto;
}
/**
 * CSS3 animations
 */
.background-glow(@backgroundFrom; @backgroundTo; @colorFrom; @colorTo;) {
  animation: bg-glow linear 1s;
  -webkit-animation: bg-glow linear 1s;
  -moz-animation: bg-glow linear 1s;
  -o-animation: bg-glow linear 1s;
  -ms-animation: bg-glow linear 1s;

  @keyframes bg-glow {
    0% {
      background-color: @backgroundFrom;
      color: @colorFrom;
    }
    50% {
      background-color: @backgroundTo;
      color: @colorTo;
    }
    100% {
      background-color: @backgroundFrom;
      color: @colorFrom;
    }
  }

  @-moz-keyframes bg-glow {
    0% {
      background-color: @backgroundFrom;
      color: @colorFrom;
    }
    50% {
      background-color: @backgroundTo;
      color: @colorTo;
    }
    100% {
      background-color: @backgroundFrom;
      color: @colorFrom;
    }
  }

  @-webkit-keyframes bg-glow {
    0% {
      background-color: @backgroundFrom;
      color: @colorFrom;
    }
    50% {
      background-color: @backgroundTo;
      color: @colorTo;
    }
    100% {
      background-color: @backgroundFrom;
      color: @colorFrom;
    }
  }

  @-o-keyframes bg-glow {
    0% {
      background-color: @backgroundFrom;
      color: @colorFrom;
    }
    50% {
      background-color: @backgroundTo;
      color: @colorTo;
    }
    100% {
      background-color: @backgroundFrom;
      color: @colorFrom;
    }
  }

  @-ms-keyframes bg-glow {
    0% {
      background-color: @backgroundFrom;
      color: @colorFrom;
    }
    50% {
      background-color: @backgroundTo;
      color: @colorTo;
    }
    100% {
      background-color: @backgroundFrom;
      color: @colorFrom;
    }
  }
}

.text-glow(@colorFrom; @colorTo;) {
  animation: text-glow linear 1s;
  -webkit-animation: text-glow linear 1s;
  -moz-animation: text-glow linear 1s;
  -o-animation: text-glow linear 1s;
  -ms-animation: text-glow linear 1s;

  @keyframes text-glow {
    0% {
      color: @colorFrom;
    }
    50% {
      color: @colorTo;
    }
    100% {
      color: @colorFrom;
    }
  }

  @-moz-keyframes text-glow {
    0% {
      color: @colorFrom;
    }
    50% {
      color: @colorTo;
    }
    100% {
      color: @colorFrom;
    }
  }

  @-webkit-keyframes text-glow {
    0% {
      color: @colorFrom;
    }
    50% {
      color: @colorTo;
    }
    100% {
      color: @colorFrom;
    }
  }

  @-o-keyframes text-glow {
    0% {
      color: @colorFrom;
    }
    50% {
      color: @colorTo;
    }
    100% {
      color: @colorFrom;
    }
  }

  @-ms-keyframes text-glow {
    0% {
      color: @colorFrom;
    }
    50% {
      color: @colorTo;
    }
    100% {
      color: @colorFrom;
    }
  }
}

.fade-in() {
  animation: fade-in linear 1.5s;
  -webkit-animation: fade-in linear 1.5s;
  -moz-animation: fade-in linear 1.5s;
  -o-animation: fade-in linear 1.5s;
  -ms-animation: fade-in linear 1.5s;

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

.ng-repeat-collapse() {
  &.ng-move,
  &.ng-enter,
  &.ng-leave {
    -webkit-transition: opacity linear 0.25s, max-height linear 0.25s;
    transition: opacity linear 0.25s, max-height linear 0.25s;
  }
  &.ng-leave.ng-leave-active,
  &.ng-move,
  &.ng-enter {
    opacity: 0;
    max-height: 0;
  }
  &.ng-leave,
  &.ng-move.ng-move-active,
  &.ng-enter.ng-enter-active {
    opacity: 1;
    max-height: 40px;
  }
}

@import url("../../../node_modules/fine-uploader/s3.jquery.fine-uploader/fine-uploader.css");
@import url("../../../node_modules/fine-uploader/s3.jquery.fine-uploader/fine-uploader-gallery.css");

.qq-gallery {
  .btn {
    &.qq-upload-button-hover {
      background-color: @orange-lighter;
    }
    &.qq-upload-button-focus {
      outline: none;
    }
  }
  .qq-thumbnail-wrapper .preview-link {
    float: left;
    width: 120px;
    height: 120px;
  }
}
.qq-button {
  ul {
    display: none;
  }
}
dialog.modal {
  background-color: transparent;
  border: none;
}
.qq-total-progress-bar {
  border-radius: 4px;
}

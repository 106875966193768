/**
 * Admin
 */

// Profile editing page
#content {
  .edit-my-profile {
    clear: both;
    .profile-picture {
      .make-md-column(6);
      .make-md-column-offset(4);
      margin-bottom: 10px;
      position: relative;
      .overlay {
        position: absolute;
        text-align: center;
        height: 100%;
        z-index: 10000;
        left: 0px;
        top: 0px;
        opacity: 0;
        transition-property: opacity;
        transition-duration: 350ms;
        transition-timing-function: ease-in-out;
        &:hover {
          opacity: 1;
        }
        &:before {
          content: " ";
          position: absolute;
          left: 0px;
          top: 0px;
          width: 100%;
          height: 100%;
          background-color: black;
          opacity: 0.5;
        }
        a {
          position: absolute;
          color: white;
          padding: 0px 20px;
          left: 0px;
          top: 50%;
          margin-top: -20px;
        }
      }
    }
    label {
      line-height: 38px;
      text-align: right;
      clear: left;
    }
    .controls {
      float: left;
      clear: left;
      padding: 0px;
    }
    .text-error {
      float: left;
      width: 100%;
    }
  }
}

//- Users & Entities
#top-nav-secondary {
  .search-filter {
    margin-right: 7px;
  }
}
#content > .admin-entities {
  .col-sm-4 {
    padding-top: 15px;
    height: 99%;
    position: relative;
    .bottom-bar {
      position: absolute;
      bottom: 0px;
      width: 100%;
      padding-right: 15px;
      background-color: @blue3-darkest;
      a.btn.add {
        width: 10%;
        float: left;
        color: white;
        line-height: 29px;
        text-align: center;
      }
    }
    .pagination {
      position: relative;
      float: left;
      height: 20px;
      width: 90%;
      color: white;
      padding: 0px 5px;
      line-height: 29px;
      .info {
        float: left;
      }
      .records,
      .page-selector {
        float: right;
      }
      .records {
        margin-left: 5px;
      }
      .page-selector {
        margin-right: 5px;
      }
    }
  }
  .right {
    .add-email {
      float: left;
      + .add {
        float: left;
        margin-top: 4px;
      }
    }
    .manage-header {
      clear: both;
    }
    h5 {
      float: left;
      width: 100%;
    }
    .email-list {
      float: left;
      width: 100%;
      list-style-type: none;
      padding: 0px;
      li {
        padding-left: 19px;
        position: relative;
        .activated,
        .not-activated {
          &:before {
            position: absolute;
            top: 0px;
            left: 0px;
            cursor: pointer;
          }
        }
        .activated {
          .icon-checkmark-circle;
          &:before {
            font-size: 20px;
            margin-top: -2px;
            margin-left: -4px;
          }
          color: @green;
        }
        .not-activated {
          .icon-checkmark-no-circle;
          &:before {
            margin-top: -8px;
            font-size: 27px;
          }
          color: @red;
        }
      }
    }
    .table.permissions > tbody > tr > td {
      padding: 0px;
      line-height: 34px;
    }
    .entity-details,
    .user-details {
      .text-danger {
        float: left;
        width: 100%;
      }
      form {
        label {
          float: left;
          width: 25%;
          line-height: 36px;
          margin-bottom: 0px;
        }
        input {
          float: left;
          width: 50%;
        }
        .controls {
          float: left;
          .btn {
            float: left;
          }
          input {
            width: auto;
          }
        }
        .login-brands-label {
          width: 100%;
        }
        .login-brands {
          label {
            &:before {
              top: 8px;
            }
            margin: 0 0 0 1em;
            width: 100%;
            line-height: 28px;
          }
          input {
            height: 0;
            margin: 0;
          }
        }
        .user-settings {
          margin-bottom: 10px;
          float: left;
          width: 100%;
          input {
            width: 0;
          }
          label {
            width: 100%;
            line-height: 15px;
            &:after {
              top: -2px;
            }
          }
        }
      }
      .manage-body,
      .manage-body form {
        float: left;
        width: 100%;
      }
      .manage-body {
        padding-top: 15px;
      }
      .password {
        float: left;
        width: 100%;
        label,
        input {
          width: 25%;
        }
      }
      #activated {
        width: 0;
        height: 0;
      }
      .activated {
        float: left;
        width: 100%;
        margin-top: 0px;
        &:before {
          top: 8px;
        }
      }
    }
  }
  .user-list,
  .entity-list {
    height: 90%;
    overflow-y: scroll;
    padding: 0px;
    li {
      .no-select;
      position: relative;
      background-color: @grey-dark;
      list-style: none;
      color: @grey-darkest;
      font-size: 16px;
      padding: 15px 0px 15px 15px;
      margin: 3px 0px;
    }
  }
  .user-list {
    li {
      cursor: move;
      input + label:before {
        background-color: white;
      }
      .controls {
        float: right;
        margin-top: -5px;
        .btn {
          float: right;
          display: none;
          margin-right: 5px;
          line-height: 30px;
          color: white;
        }
      }
      &.active,
      &:hover {
        background-color: @orange;
        color: white;
        &:before {
          background-position: 40px 0;
          right: 36px;
        }
        .controls .btn {
          display: block;
        }
      }
    }
    label {
      float: right;
      margin-right: 15px;
      margin-top: 5px;
    }
    &.drag-overlay {
      height: 100%;
      overflow: visible;
      li {
        background-color: green;
        padding: 10px;
      }
    }
  }
  .entity-list {
    li {
      padding: 10px;
      position: static;
      .controls {
        float: right;
        margin-top: -35px;
        margin-right: 7px;
        a {
          text-decoration: none;
        }
        .settings:before {
          margin-right: 5px;
          color: @grey-darkest;
        }
      }
      &.active,
      &:hover {
        background-color: @grey-darker;
        color: black;
        > ul.entities-user-sublist {
          border-color: @grey-darkest;
        }
      }
      > h4 {
        margin: 0px;
        padding: 7px 0px;
      }
      ul.entities-user-sublist {
        padding: 0px;
        margin: 0px;
        border-style: dashed;
        li {
          color: @grey-darkest;
          cursor: move;
          position: relative;
          .controls {
            display: none;
          }
          &:hover {
            background-color: @orange;
            color: white;
            .controls {
              display: block;
              float: right;
              margin-top: 0px;
            }
          }
        }
        &.empty {
          padding: 20px;
          position: relative;
          &:before {
            display: block;
            content: "Drag users here...";
            position: absolute;
            top: 10px;
            left: 30%;
            color: @grey-darkest;
          }
        }
        &.dropping {
          color: @orange;
          padding: 0px;
          &:before {
            display: none;
          }
          &.disabled {
            color: transparent;
            cursor: no-drop;
          }
        }
      }
    }
  }
  .permissions,
  .edit-details {
    padding: 10px 0px 15px 15px;
    background-color: @grey-dark;
    .manage-header {
      width: 100%;
      color: @gray-light;
      padding-top: 15px;
      padding-bottom: 15px;
      font-size: 18px;
      font-weight: 100;
      h3,
      h4 {
        display: inline-block;
        margin-left: 50px;
        margin-top: 5px;
      }
      h3 {
        font-size: 18px;
      }
      h4 {
        font-size: 15px;
      }
    }
    .manage-body {
      color: @gray-light;
      &:after {
        content: " ";
        position: absolute;
        width: 85%;
        height: 2px;
        background-color: white;
      }
      &:last-child:after {
        background-color: transparent;
      }
      table.permissions {
        td {
          &:nth-child(odd) {
            padding-top: 16px;
          }
        }
      }
      table {
        text-align: right;
        thead > tr > th {
          text-align: right;
          font-weight: normal;
          border-bottom: 1px solid transparent;
        }
      }
      div.col-md-6 {
        padding: 0px;
      }
      button {
        margin: 10px 0px;
      }
      input[type="text"] {
        margin: 5px 0px;
        width: 100%;
      }
    }
  }
}

.user-permissions {
  .side-bar {
    float: left;
    width: 25%;
    padding: 15px;
    h3 {
      font-size: 18px;
    }
    .permission {
      width: 100%;
      float: left;
      padding: 0px 10px;
      height: 45px;
      color: white;
      padding-left: 15px;
      margin-bottom: 7px;
      &.delete {
        background-color: @green;
      }
      &.create {
        background-color: @red;
      }
      &.update {
        background-color: @orange-lighter;
      }
      &.collaborate {
        background-color: @purple;
      }
      &.read {
        background-color: @blue3;
      }
      &.delete.disabled {
        background-color: lighten(@green, 10%);
      }
      &.create.disabled {
        background-color: lighten(@red, 10%);
      }
      &.update.disabled {
        background-color: lighten(@orange-lighter, 10%);
      }
      &.collaborate.disabled {
        background-color: lighten(@purple, 10%);
      }
      &.read.disabled {
        background-color: lighten(@blue3, 10%);
      }
      span {
        float: left;
        line-height: 45px;
        font-size: 18px;
        font-weight: 100;
      }
      .controls {
        .checkbox {
          float: right;
          margin-top: 13px;
        }
      }
    }
    .controls .btn {
      margin-left: 0px;
    }
  }
  .permission-wrapper {
    float: left;
    width: 75%;
    height: 100%;
    overflow: scroll;
    white-space: nowrap;
  }
  .main {
    display: inline-block;
    height: 100%;
    white-space: nowrap;
    .column {
      display: inline-block;
      width: 230px;
      margin-right: 7px;
      vertical-align: top;
      margin-top: 5px;
      margin-top: -48px;
      height: 100%;
      padding-top: 58px;
      .permissions {
        width: 100%;
        height: 100%;
        float: left;
        overflow-y: auto;
        overflow-x: hidden;
        .permission {
          float: left;
          line-height: 69px;
          padding-left: 10px;
          width: 100%;
          height: 69px;
          background-color: @grey-dark;
          margin-bottom: 7px;
          position: relative;
          cursor: pointer;
          .text {
            width: 100%;
            .text-truncate();
          }
          .sidebar {
            position: absolute;
            right: 0px;
            top: 0px;
            height: 100%;
            width: 15px;
          }
          &.next {
            position: relative;
            &:before {
              content: " ";
              width: 0;
              height: 0;
              position: absolute;
              top: 50%;
              right: 5px;
              border-top: 10px solid transparent;
              border-bottom: 10px solid transparent;
              border-left: 5px solid @grey-darkest;
              margin-top: -10px;
            }
            &:after {
              content: " ";
              width: 0;
              height: 0;
              position: absolute;
              top: 50%;
              right: 6px;
              border-top: 10px solid transparent;
              border-bottom: 10px solid transparent;
              border-left: 5px solid @grey-dark;
              margin-top: -10px;
            }
          }
          // &.ancestor {
          //   background-color: lighten(@grey-darkest, 20%);
          // }
          &.selected {
            background-color: @grey-darkest;
            color: white;
          }
          &.delete {
            background-color: @green;
            color: white;
          }
          &.create {
            background-color: @red;
            color: white;
          }
          &.update {
            background-color: @orange-lighter;
            color: white;
          }
          &.collaborate {
            background-color: @purple;
            color: white;
          }
          &.read {
            background-color: @blue3;
            color: white;
          }
        }
        .permission[class*="sidebar-low"],
        .permission[class*="sidebar-high"] {
          .sidebar:after,
          .sidebar:before {
            content: " ";
            position: absolute;
            right: 0;
            height: 34.5px;
            width: 15px;
            background-color: transparent;
          }
        }
        .permission {
          &.sidebar-low-selected .sidebar:after,
          &.sidebar-high-selected .sidebar:before {
            background-color: @grey-darkest;
          }
          &.sidebar-low-delete .sidebar:after,
          &.sidebar-high-delete .sidebar:before {
            background-color: @green;
          }
          &.sidebar-low-create .sidebar:after,
          &.sidebar-high-create .sidebar:before {
            background-color: @red;
          }
          &.sidebar-low-update .sidebar:after,
          &.sidebar-high-update .sidebar:before {
            background-color: @orange-lighter;
          }
          &.sidebar-low-collaborate .sidebar:after,
          &.sidebar-high-collaborate .sidebar:before {
            background-color: @purple;
          }
          &.sidebar-low-read .sidebar:after,
          &.sidebar-high-read .sidebar:before {
            background-color: @blue3;
          }
        }
        .permission[class*="sidebar-high"] {
          .sidebar:before {
            top: 0;
          }
        }
        .permission[class*="sidebar-low"] {
          .sidebar:after {
            bottom: 0;
          }
        }
      }
    }
    .breadcrumbs {
      width: 100%;
      float: left;
      height: 48px;
      padding-top: 10px;
      .breadcrumb {
        width: 205px;
        color: white;
        height: 38px;
        line-height: 38px;
        float: left;
        display: inline;
        background-color: @orange-lighter;
        margin-right: 25px;
        margin-left: 8px;
        position: relative;
        padding: 0px;
        padding-left: 5px;
        border-radius: 0px;
        font-weight: 100;
        font-size: 18px;
        .text {
          width: 100%;
          float: left;
          .text-truncate();
        }
        &:first-child {
          width: 180px;
          margin-left: 25px;
          padding-left: 10px;
        }
        &:after,
        &:before {
          content: " ";
          position: absolute;
          width: 0;
          height: 0;
        }
        &:after {
          border-top: 19px solid transparent;
          border-bottom: 19px solid transparent;
          border-left: 25px solid @orange-lighter;
          right: -25px;
        }
        &:before {
          border-top: 19px solid @orange-lighter;
          border-bottom: 19px solid @orange-lighter;
          border-left: 25px solid transparent;
          left: -25px;
        }
        &:last-child {
          &:after {
            display: none;
          }
        }
        &.disabled {
          background-color: lighten(@orange-lighter, 10%);
          &:after {
            border-left-color: lighten(@orange-lighter, 10%);
          }
          &:before {
            border-top-color: lighten(@orange-lighter, 10%);
            border-bottom-color: lighten(@orange-lighter, 10%);
          }
        }
      }
    }
  }
}

/**
 * TruTerritory
 */

.map-drop,
.collection-drop {
  list-style-type: none;
  width: 100%;
  position: relative;
  cursor: pointer;
  padding: 2px;
  padding-left: 10px;
  border-left: 5px solid #dadbdd;
  background-color: #f7f8fa;
  margin-bottom: 1px;
  line-height: 23px;
  height: 24px;
  &.active {
    background-color: #fde6cf;
    border-left: 5px solid #f5993e;
  }
  .del {
    position: absolute;
    top: 1px;
    right: 7px;
    height: 23px;
    line-height: 23px;
  }
}
.collection-drop .map-type {
  position: absolute;
  right: 4px;
  top: 4px;
  margin-top: 0px;
}

#top-nav-secondary {
  .location {
    margin-top: 5px;
    margin-left: 8px;
    position: relative;
    &.active:before {
      @-webkit-keyframes glow {
        0% {
          color: @grey-darkest;
        }
        50% {
          color: white;
        }
        100% {
          color: @grey-darkest;
        }
      }
      @-moz-keyframes glow {
        0% {
          color: @grey-darkest;
        }
        50% {
          color: white;
        }
        100% {
          color: @grey-darkest;
        }
      }
      @-o-keyframes glow {
        0% {
          color: @grey-darkest;
        }
        50% {
          color: white;
        }
        100% {
          color: @grey-darkest;
        }
      }
      @keyframes glow {
        0% {
          color: @grey-darkest;
        }
        50% {
          color: white;
        }
        100% {
          color: @grey-darkest;
        }
      }
      -webkit-animation: glow 1s infinite; /* Safari 4+ */
      -moz-animation: glow 1s infinite; /* Fx 5+ */
      -o-animation: glow 1s infinite; /* Opera 12+ */
      animation: glow 1s infinite; /* IE 10+, Fx 29+ */
    }
  }
  .map-name {
    color: white;
    font-weight: normal;
  }
}
#side-nav-right {
  ul {
    a.active,
    .active > a {
      background-color: @bookmark-background-active;

      &:hover {
        background-color: @bookmark-background-active-hover;
      }
    }

    & > li {
      display: inline-block;
      margin-bottom: 0px;
    }
    &.bookmarks {
      & > li {
        > a {
          padding-left: @bookmark-column-2-start;
          padding-top: @bookmark-line-padding;
          padding-bottom: @bookmark-line-padding;
        }
      }
    }
    &.children {
      & > li {
        padding-left: @bookmark-column-3-start;
        a {
          padding-left: @bookmark-handle-width + 3px;
          width: 100%;
        }
      }
    }
  }
}

.icon.help {
  display: block;
  width: 20px;
  height: 20px;
  margin: 10px;
  line-height: 20px;
  border-radius: 10px;
  background-color: @btn-default-disabled;
  color: white;
  text-align: center;
  font-size: 14px;
  font-family: Verdana;
  box-shadow: 0 0 0.5px 2px white;
  cursor: pointer;
  &:before {
    content: "?";
  }
}
#top-nav-secondary ul li > a.icon.help {
  padding: 0;
}
#side-nav-right .truterritory-bookmarks {
  height: inherit;
  padding-bottom: 106px;
  display: block;
  .bookmarks-list {
    float: left;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 1em;
  }
  .dragging .collapsible {
    display: none;
  }
  .workspace {
    float: left;
    width: 100%;
    margin-bottom: 1em;
    .closed-count, .closed-indicator {
      transition: font-size 0.1s ease-out 0.1s;
    }
    &.opened .closed-count, &.opened .closed-indicator{
      transition: none;
      font-size: 0;
    }
  }
  li {
    cursor: pointer;
    position: relative;
    a {
      position: relative;
      padding: 0px 15px 0px @bookmark-column-2-start;
      z-index: 10;
      &:hover {
        background-color: @blue2;
        .controls, .handle {
          visibility: visible;
        }
      }
    }
    span {
      line-height: 16px;
      vertical-align: middle;
    }
    .triangle {
      position: absolute;
      left: (@bookmark-column-2-start + @bookmark-handle-width) / 2 - (@bookmark-total-line-height / 2) / 2; // Put the triangle halfway between the item and the handle
      z-index: 11;
      width: @bookmark-total-line-height / 2;
      height: @bookmark-total-line-height;
      line-height: @bookmark-line-height;
      &:before {
        content: " ";
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 5px solid @grey-darkest;
        margin-top: -5px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -2.5px;
        --webkit-transition: --webkit-transform 0.25s;
        transition: transform 0.25s;
      }
      &:hover {
        background-color: @blue2;
      }
      &.open:before {
        --webkit-transform: rotate(90deg);
        transform: rotate(90deg);
      }
    }
    .handle {
      visibility: hidden;
      display: inline-block;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: @bookmark-handle-width;
      background: repeating-linear-gradient(135deg, transparent, transparent 3px, #f5993e 3px, #f5993e 6px);
      cursor: move;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    .controls {
      visibility: hidden;
      display: inline-block;
      float: right;
      line-height: @bookmark-line-height;
      height: @bookmark-line-height;

      > button {
        height: @bookmark-line-height;
        width: unset;
      }
    }

    &:hover {
      background-color: transparent;
    }
  }
}

.collapsible {
  width: 100%;
  display: grid;
  grid-template-rows: 1fr;
  transition: grid-template-rows 0.2s ease-out;
}
.collapsible.closed, .closed>.collapsible { // the collapsible closes if it _or its immediate parent_ has the closed class.
  grid-template-rows: 0fr;
  &>* {
    overflow: hidden;
  }
}
.collapsible.opened, .opened>.collapsible {
  &>* {
    overflow: visible;
    animation: 0.3s delay-overflow;
  }
}
@keyframes delay-overflow {
  // This animation allows `overflow: visible` to be removed from the element after a delay.
  // Here we use to it to allow overflow from the collection element in the right sidebar, but only after
  // the transition to open has fully completed.
  from { overflow: hidden; }
}

#content #top-nav-slider.mapping {
  position: absolute; /** Having this here breaks the full-screen css theme editor. But not having it breaks initial loading of tiles. @todo: decide on a fix **/
  left: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: inherit;
  #truterritory-theme-editor {
    padding: 25px;
    .left-side {
      position: relative;
      float: left;
      width: 25%;
      padding: 0px 5px;
      h4.theme-name {
        padding-right: 70px;
        width: 100%;
        color: @grey-text;
        border: none;
      }
      .delete-theme {
        position: absolute;
        top: 6px;
        right: 5px;
      }
      .editable-wrap {
        width: 100%;
        .editable-input { 
          float: left;
          height: 30px;
          width: calc(100% - 157px);
          font-size: 18px;
          margin-left: -7px;
          margin-top: 4px;
        }
        .editable-buttons{
          display: block;
          width: 147px;
          float: left;
          .btn { 
            height: 30px;
          }
        }

      }
      .attribute {
        float: left;
        width: 100%;
      }
      label {
        float: left;
        width: 50%;
        line-height: 27px;
        font-weight: 100;
      }
      input {
        margin-top: 0;
        float: right;
        width: 100px;
        height: 25px;
        padding: 0 5px;
        -webkit-box-shadow: 0px 0px 0px 1px #ffffff;
        -moz-box-shadow: 0px 0px 0px 1px #ffffff;
        box-shadow: 0px 0px 0px 1px #ffffff;
        &:focus {
          -webkit-box-shadow: inset 0 0 0 #d78f48, 0 0 6px #d78f48;
          box-shadow: inset 0 0 0 #d78f48, 0 0 6px #d78f48;
        }
      }
      .select-holder {
        padding: 0;
        width: 100px;
        height: 25px;
        float: right;
        select {
          height: 20px;
          line-height: 12px;
        }
        &:after {
          line-height: 24px;
        }
      }
      .label-format-header,
      .format-labels {
        float: left;
        width: 100%;
      }
      .label-format {
        float: left;
        clear: both;
        width: 100%;
        .number,
        .select-holder {
          float: left;
        }
        .number {
          width: 25%;
        }
        .select-holder {
          width: 50%;
          margin-right: 2%;
        }
        input[type="checkbox"] {
          width: 0;
          height: 0;
        }
        .checkbox {
          width: 1%;
          margin-left: 3%;
          margin-top: 6px;
          &:after {
            top: -5px;
          }
        }
      }
      .controls {
        float: left;
        width: 100%;
      }
    }
    .right-side {
      float: left;
      width: 75%;
    }
    .tabs {
      float: left;
      width: 100%;
      .tab {
        display: inline-block;
        margin-right: 5px;
        padding: 5px 10px;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
        background-color: @grey-darker;
        cursor: pointer;
        &.active {
          background-color: @grey-dark2;
        }
      }
    }
    .tab-content {
      float: left;
      width: 100%;
      position: relative;
    }
    .legend-editor,
    .css-editor {
      float: left;
      width: 100%;
      height: 100%;
      > section > div {
        height: 300px;
      }
    }
    .show-legend-header,
    .custom-legend-header {
      float: left;
    }
    .legend {
      padding: 20px;
      .controls,
      .entries,
      .headers {
        float: left;
        width: 100%;
      }
      .controls {
        margin-top: 10px;
      }
      .headers {
        label {
          float: left;
          width: 100%;
          font-weight: normal;
        }
      }
      #custom-header-label {
        &:before,
        &:after {
          margin-top: 7px;
        }
        input {
          height: 28px;
        }
      }
    }
    .help-holder {
      float: left;
      width: 100%;
      pre {
        display: inline-block;
        margin: 5px 0px;
      }
      .block {
        clear: left;
      }
    }
    .help {
      float: left;
      width: 100%;
    }
    .help-button {
      float: left;
      width: 100%;
      &:before {
        content: "?";
        border-radius: 50%;
        border: 1px solid #dadbdd;
        border: 1px solid @grey-darker;
        background-color: white;
        padding: 1px 6px;
        font-size: 12px;
        cursor: pointer;
      }
    }
    code-mirror {
      float: left;
      width: 100%;
      fine-uploader li {
        cursor: pointer;
      }
    }

    #expanded-css .CodeMirror-scroll {
      background-color: @grey-dark2;
    }

    #expanded-css .CodeMirror-gutters {
      background-color: @grey-darker;
    }
  }
  #truterritory-workspace-module {
    position: relative;
    width: 100%;
    padding: 20px;
    .empty {
      .no-select;
      cursor: default;
      font-size: 18px;
    }
    .share-default {
      .make-md-column(4);
      position: absolute;
      top: 50px;
      right: 0px;
      text-align: center;
    }
    .info {
      float: left;
    }
    .dropdown-selector {
      width: 25%;
      .item {
        position: relative;
        .icon-share {
          .icon-share;
        }
      }
      img {
        position: absolute;
        width: 20px;
        left: 7px;
        top: 50%;
        margin-top: -10px;
      }
    }
    .map-drop {
      .ng-repeat-collapse();
      &.active + .ng-leave.ng-leave-active {
        -webkit-transition: none;
        transition: none;
        padding: 0px !important;
      }
      &.active.ng-enter.ng-enter-active {
        -webkit-transition: none;
        transition: none;
      }
    }
    .map-list,
    .collection-list {
      max-height: @layers-max-scroll-height;
      overflow: auto;
      float: left;
      width: 100%;
      padding: 0px;
      margin: 0px;
    }
    .workspaces {
      .make-md-column(3);
      h4 {
        margin-top: 50px;
      }
      .select-holder {
        width: 85%;
        padding: 5px 10px;
      }
      button.add {
        margin-top: 2px;
        margin-left: 4px;
        position: absolute;
        top: 0;
        right: 20px;
      }
      .map-list {
        list-style-type: none;
        padding: 0px;
      }
    }
    .collections {
      .make-md-column(5);
      .search {
        float: left;
      }
      .collection-list {
        padding-left: 0px;
        min-width: 100%;
        min-height: 28px;
        .active {
          color: black;
        }
        .ui-sortable-helper {
          z-index: 5000;
        }
      }
      .collection {
        float: left;
        width: 50%;
        padding: 3px 10px;
        font-size: 16px;
        font-weight: 100;
        list-style-type: none;
        position: relative;
        cursor: pointer;
        line-height: 20px;
        .text {
          .text-truncate();
          float: left;
          width: 100%;
          padding-right: 15px;
          padding-left: 20px;
          position: relative;
          &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
          }
          &:before {
            position: absolute;
            top: 0;
            left: 0;
            content: " ";
            line-height: 20px;
            margin-left: 1px;
            font-size: 12px;
          }
          &.point,
          &.multipoint {
            .icon-map-marker;
          }
          &.polygon,
          &.multipolygon {
            .icon-truterritory-polygon;
          }
          &.linestring,
          &.multilinestring {
            .icon-truterritory-lines;
          }
          &.geometry {
            .icon-truterritory-geometry;
          }
        }
        .btn {
          position: absolute;
          right: 5px;
          top: 1px;
        }
        .map-type {
          position: absolute;
          right: 0px;
          margin: 0px;
          &.list,
          &.point {
            margin-right: 4px;
            margin-top: 0px;
          }
        }
      }
    }
    .user-selector {
      .make-md-column(2);
      & > div {
        max-height: @layers-max-scroll-height;
        overflow-y: auto;
        padding-bottom: 7px;
        & > div:nth-of-type(odd) {
          background-color: @table-bg-accent;
        }
      }
      .users {
        padding: 4px;
        position: relative;
        float: left;
        width: 100%;
        .text {
          float: left;
        }
        .del {
          position: absolute;
          top: auto;
          bottom: auto;
          right: 0;
          margin-top: -4px;
          margin-right: 4px;
        }
      }
    }
  }
}
#map,
.map-holder {
  z-index: 0;
  float: left;
  height: 100%;
  width: 100%;
  .map-feature-label {
    position: absolute;
    text-align: center;
    height: 0;
    z-index: 1000;
    line-height: 1.2em;
    span {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      display: block;
      pointer-events: none;
      color: #333333;
    }
    span.icon-edit {
      position: absolute;
      pointer-events: auto;
    }
    .toolbar {
      font-size: 14px;
      line-height: 1.4em;
    }
  }

  .selection-status {
    position: absolute;
    z-index: 101;
    bottom: 10px;
    left: 10px;
    padding: 0.5em 1em;
    background-color: #ffffffe3;
    cursor: auto;
    ul.actions {
      margin: 0 0 0 0.5em;
      padding: 0;
      display: inline;
      font-size: 1.1em;
    }
    ul.actions li {
      list-style-type: none;
      display: inline;
      margin: 0 0.3em;
      &::after {
        content: "|";
        margin-left: 0.6em;
      }
      &:last-child::after {
        content: "";
        margin-left: none;
      }
    }
    ul.actions li a {
      cursor: pointer;
    }
    ul.actions li a.loading::after {
      display: inline-block;
      width: 0.8em;
      animation: loading steps(1,end) 1s infinite;
      content: '';
    }

    @keyframes loading {
      0%   { content: ''; }
      25%  { content: '.'; }
      50%  { content: '..'; }
      75%  { content: '...'; }
      100% { content: ''; }
    }
  }

  .selection-summary {
    z-index: 100;
    cursor: auto;
    background-color: #ffffffe3;
    padding: .5em 1em;
    position: absolute;
    top: 10px;
    left: 50px;
    max-height: 70%;
    max-width: 30%;
    min-width: 200px;
    overflow: auto;
    td:first-child {
      text-align: right;
      padding-right: 1em;
    }
  }
  .marquee-select {
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px dashed black;
  }
}

#truterritory {
  position: relative;
  overflow: hidden;
  .map-holder {
    position: absolute;
    left: 0;
    top: 0;
  }
  .toggle-triangle {
    cursor: pointer;
    position: absolute;
    right: 0px;
    top: 50%;
    color: white;
    z-index: 999;
    overflow: hidden;
    margin: auto;
    width: 28px;
    height: 75px;
    .triangle {
      width: 75px;
      height: 75px;
      border-radius: 50%; 
      background-color: @blue2;
      &:before {
        content: "+";
        position: absolute;
        top: 0px;
        left: 13px;
        line-height: 75px;
        font-size: 18px;
      }
    }
  }
  .menu {
    float: left;
    background-color: #fff;
    border: 1px solid @grey-darker;
    border-radius: 3px;
    box-shadow: 0px 0px 25px -7px black;
    min-width: 175px;
    h3 {
      font-size: 12px;
      font-style: italic;
      margin: 8px 0px 0px 0px;
      padding-left: 8px;
      &.empty {
        margin-top: 14px;
      }
    }
    ul {
      padding: 5px 0px;
      margin-bottom: 5px;
      list-style-type: none;
      cursor: default;
      li {
        padding: 3px 10px;
        cursor: pointer;
        line-height: 15px;
        &:hover {
          color: white;
          background-color: @blue-light;
          //min-width: 150px;
          //margin-left: -1px;
          //padding-left: 11px;
          //padding-right: 11px;
        }
      }
    }
  }
  [map-point-detail] {
    display: block;
    min-width: 250px;
    padding: 0 15px;
    margin-top: 25px;
    overflow: auto;
  }
  .map-point-detail {
    .left-controls {
      float: left;
      margin-top: -10px;
      margin-right: -15px;
    }
    .controls {
      float: right;
      position: absolute;
      right: 20px;
      top: 4px;
      right: 4px;
      .delete-3 {
        margin-top: 4px;
        margin-left: 2px;
        float: right;
        &:before {
          color: @grey-darkest;
          font-size: 10px;
        }
      }
    }
    form {
      width: 100%;
      table {
        table-layout: fixed;
        width: 100%;
        tr {
          width: 100%;
        }
        td {
          .text-truncate;
        }
      }
      .form-group {
        margin-bottom: 0;
      }
      label {
        .text-truncate;
        line-height: 22px;
      }
      input {
        height: 22px;
        width: 100%;
      }
    }
    .copy-holder {
      display: inline-block;
    }
  }
  .progress-indicator {
    .wrapper {
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.45);
      position: fixed;
      bottom: 0;
      z-index: 10000;
      margin-left: -10%;
      .holder {
        position: absolute;
        top: 30%;
        left: 50%;
        width: 600px;
        margin-left: -300px;
        color: @grey-darkest;
      }
      .header {
        font-size: 55px;
      }
      .percent {
        font-size: 45px;
        float: left;
        line-height: 45px;
        margin-top: -13px;
        margin-left: 10px;
      }
      .bar {
        width: 80%;
        height: 15px;
        float: left;
        display: inline;
        background-color: white;
        opacity: 0.7;
        .inner {
          background-color: black;
          width: 0%;
          height: 100%;
          opacity: 0.45;
        }
      }
    }
  }
  .right-side-menu {
    padding: 0px;
    width: 0px;
    background-color: transparent;
    overflow: visible;
    z-index: 1001;
    transition: width 400ms ease-in;
  }
  #side-menu {
    top: 105px;
    height: 100%;
    padding: 0px;
    background-color: @grey;
    z-index: 999;
    .toggle-triangle.open {
      right: auto;
      left: -28px;
      top: 50%;
      .triangle:before {
        content: "–";
      }
    }
    .inner {
      float: left;
      width: 100%;
      height: 100%;
      // overflow-y: scroll;
      // padding-top: 15px;
    }
    .tab-content {
      height: 100%;
      width: 100%;
      float: left;
      -webkit-transition: all ease-in 0.1s;
      -moz-transition: all ease-in 0.1s;
      -o-transition: all ease-in 0.1s;
      transition: all ease-in 0.1s;
      &.ng-hide-remove {
        opacity: 0;
        display: block !important;
      }
      &.ng-hide-remove.ng-hide-remove-active {
        opacity: 1;
      }
    }
    .tabs {
      width: 100%;
      height: 50px;
      text-align: center;
      padding-top: 3px;
      position: absolute;
      top: 0px;
      left: 0px;
      table-layout: fixed;
      td {
        padding-right: 2px;
        &:last-child {
          padding-right: 0px;
        }
      }
      button {
        width: 100%;
        // display: table-cell;
        height: 40px;
        margin: 0px;
        border-radius: 0px;
        &.active {
          background-color: @blue3-darkest;
        }
      }
    }
    .map-tab {
      .select-holder {
        float: left;
        width: 40%;
        margin-top: 15px;
        margin-left: 5px;
        .add {
          position: absolute;
          right: -25px;
          line-height: 30px;
        }
        .edit {
          position: absolute;
          right: -25px;
          line-height: 30px;
        }
        .folder {
          position: absolute;
          right: -50px;
          line-height: 30px;
        }
        .del {
          position: absolute;
          right: -50px;
          line-height: 30px;
        }
      }
      .input-search {
        float: right;
        width: 40%;
        margin-top: 15px;
        margin-right: 15px;
      }
      .input-search {
        &:before {
          line-height: 35px;
        }
        &.centered {
          width: 60%;
          margin: 15px auto -15px auto;
          float: none;
        }
        input {
          float: left;
          width: 100%;
          margin-top: 0px;
        }
      }
      .dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 5000;
        float: left;
        // min-width: 165px;
        width: 100%;
        padding: 5px 0px;
        margin: 0;
        list-style: none;
        font-size: 12px;
        background-color: white;
        border: 1px solid @grey-lightest;
        border-radius: 0px;
      }
      @dropdown-toggle-padding-right: 65px;
      .dropdown-toggle {
        .text-truncate;
        .icon-arrow-down-after;
        float: left;
        line-height: 25px;
        padding: 0px 5px;
        background-color: white;
        width: 130px;
        padding-right: @dropdown-toggle-padding-right;
        cursor: pointer;
        font-size: 12px;
        &:after {
          position: absolute;
          right: 5px;
          font-size: 11px;
        }
      }
      .collections-wrapper {
        width: 100%;
        height: 100%;
        padding-top: 65px;
      }
      .collections {
        width: 100%;
        height: 100%;
        overflow: scroll;
        padding: 0px;
        list-style-type: none;
        // padding-bottom: 90px;
        // margin-top: 40px;
      }
      .collection {
        width: 100%;
        margin-top: 3px;
        padding: 0 15px 3px 35px;
        position: relative;
        float: left;
        background-color: @grey;
        border-left: 5px solid @grey-darker;
        .draggable {
          position: absolute;
          top: 0;
          left: 0px;
          width: 9px;
          height: 100%;
          background: repeating-linear-gradient(135deg, transparent, transparent 3px, @grey-darker 3px, @grey-darker 6px);
        }
        &.visible {
          background-color: lighten(@orange, 30%);
          border-left-color: @orange;
          .draggable {
            background: repeating-linear-gradient(135deg, transparent, transparent 3px, @orange 3px, @orange 6px);
          }
        }
        .name,
        .label {
          padding-left: 15px;
          cursor: pointer;
        }
        .name {
          .text-truncate();
          width: 100%;
          height: 21px;
          line-height: 26px;
          font-size: 15px;
          font-weight: 100;
          float: left;
        }
        .label {
          font-size: 12px;
        }
        input[type="checkbox"] {
          position: absolute;
          top: 1px;
        }
        .checkbox {
          position: absolute;
          left: 25px;
          top: 0px;
          &:before {
            background-color: white;
          }
        }
        .collection-controls {
          position: absolute;
          right: 0px;
          top: 0px;
          padding-right: 15px;
          display: flex;
          .btn {
            height: 40px;
            line-height: 40px;
          }
          .arrow-right {
            color: @grey-text;
            margin-left: 0px;
          }
          .filter {
            color: @grey-darkest;
            margin-top: 2px;
            &.active {
              color: @grey-text;
            }
          }
        }
        .controls {
          float: left;
          width: 100%;
          padding-left: 20px;
          padding-right: 20px;
          margin-top: 10px;
          position: relative;
          .checkbox,
          .map-type {
            position: absolute;
            left: -10px;
            margin-right: 4px;
          }
          .del {
            position: absolute;
            right: -10px;
            margin-right: 4px;
          }
        }
        .theme-selector,
        .label-selector {
          float: left;
          width: calc(100% - @dropdown-toggle-padding-right);
          height: 25px;
          margin-bottom: 5px;
        }
        .label-selector {
          border-color: white;
          background-color: white;
          overflow: visible;
          position: relative;
          line-height: 25px;
          &.open {
            .dropdown-toggle {
              background-color: @orange;
              color: white;
            }
          }
          .dropdown {
            width: 100%;
            height: 150px;
            overflow: hidden;
            overflow-y: scroll;
          }
          .option {
            float: left;
            width: 100%;
            padding: 0px 5px;
            cursor: pointer;
            position: relative;
            &:hover {
              background-color: #f5f5f5;
            }
            &.theme-label {
              font-style: italic;
              background-color: lighten(@orange, 35%);
            }
          }
          &.open {
            background-color: @orange;
          }
        }
        .btn {
          margin-left: 7px;
        }
      }
      // Styling for theme selector
      .theme-selector {
        position: relative;
        &.open {
          .dropdown-toggle {
            background-color: @orange;
            color: white;
          }
        }
        > .copy {
          position: absolute;
          line-height: 27px;
          right: 42px;
        }
        > .edit {
          position: absolute;
          line-height: 27px;
          right: 20px;
        }
        .theme,
        .add-new {
          position: relative;
          float: left;
          width: 100%;
          line-height: 32px;
          padding: 0px 5px;
          cursor: pointer;
          .btn {
            position: absolute;
            line-height: 32px;
            height: 32px;
            top: 0px;
            right: 5px;
            color: @grey-darkest;
          }
          &:hover {
            background-color: @grey-lightest;
          }
        }
        .theme.selected {
          background-color: @orange;
          color: white;
        }
        &.open {
          .theme-name {
            color: white;
            background-color: @orange;
          }
          .caret:before,
          > .edit,
          > .copy {
            color: white;
          }
        }
      }
      .dropdown-toggle {
        width: 100%;
      }
      .dropdown-menu {
        border: none;
        border-radius: 0px;
        margin-top: -2px;
        width: 250px;
        padding-bottom: 0px;
        .theme {
          float: left;
          width: 100%;
          padding: 5px;
          .theme-name {
            .text-truncate();
            color: @grey-darkest;
            margin: 0px;
            width: auto;
          }
          .controls {
            margin: 0;
            width: 50px;
            margin-right: 0px;
            margin-top: -6px;
            .edit:before {
              color: @grey-darkest;
            }
            .del {
              margin-top: 0px;
            }
          }
        }
      }
    }
    .entity-tab {
      h3 {
        margin-left: 7px;
      }
      .search {
        margin-left: 10px;
      }
      .entities-list {
        padding: 0px 25px;
        li {
          height: 60px;
          line-height: 60px;
          background-color: white;
          list-style: none;
          padding-right: 25px;
          padding-left: 15px;
          margin-bottom: 5px;
          label {
            margin-top: 20px;
            float: right;
            line-height: 0px;
          }
        }
      }
    }
    .collections-tab {
      padding-left: 35px;
      .filter-header {
        float: left;
        width: 100%;
      }
      .select-collection {
        float: left;
        border: none;
        height: 35px;
        margin-right: 7px;
        background-position: 97% -104px;
        select {
          height: 35px;
        }
      }
      .theme-selector {
        width: 45%;
        float: left;
        .theme-selector-dropdown {
          width: 100%;
          left: 0;
          .theme-overlay {
            .btn.edit {
              margin-top: 6px;
            }
          }
        }
      }
      h4 {
        margin-top: 15px;
        display: block;
        float: left;
        font-weight: 400;
      }
      .filters-holder {
        float: left;
        width: 100%;
      }
      .filter-creator {
        width: 100%;
        float: left;
        .btn {
          line-height: 40px;
          margin-top: 0px;
        }
        .add {
          right: 25px;
        }
        .del {
          right: 0px;
        }
        .filter {
          padding-right: 30px;
        }
      }
      .new-filter {
        position: relative;
        float: left;
        width: 100%;
      }
      .controls {
        float: left;
      }
      #truterritory-collection-list {
        margin-top: 15px;
        padding: 0px 25px;
        background-color: @grey;
        float: left;
        #truterritory-column-selector {
          margin-top: 15px;
          float: right;
          font-size: 18px;
          span {
            cursor: pointer;
          }
        }
        #truterritory-feature-data {
          float: left;
          width: 100%;
          th > h4 {
            margin-right: 3px;
            margin-top: 7px;
          }
          td {
            text-align: left;
          }
        }
        .controls {
          float: right;
        }
      }
      .back-button { // back button
        margin-left: -35px;
      }
    }
  } // .right
}

// Modals
#truterritory-save-map-modal, .modal.truterritory-save-map {
  input[type="text"] {
    width: 75%;
  }
  label.checkbox {
    padding-left: 28px;
    &:before {
      top: 4px;
    }
  }
  .col-md-6 {
    padding: 0px;
  }
  label.checkbox {
    &:before {
      top: 12px;
    }
  }
  input[type="checkbox"]._checked + label.checkbox:after {
    top: 5px;
  }
  .form-group.disabled {
    opacity: 0.6;
  }
  label.checkbox:empty {
    padding-top: 0;
    &::before {
      top: 0;
    }
  }
  input[type="checkbox"]._checked + label.checkbox:empty::after {
    top: -4px;
  }
  table.collection-control-options {
    th {
      padding-right: 0.5em;
      font-size: medium;
      font-weight: normal;
    }
    td {
      font-weight: 100;
    }
  }
  .actions {
    table {
      width: 95%;
    }
    th, td {
      text-align: center;
    }
    td .select-holder {
      width: 100%;
    }
    input[type="text"] {
      width: 100%
    }
    .add-action {
      display: inline-block;
      cursor: pointer;
      margin: 1em;
      button {
        margin-right: 0.3em;
        float: left;
      }
    }
  }
}
.modal.truterritory-save-map .modal-dialog {
  @media screen and (min-width: 1000px) {
    width: 900px;
  }
}
#truterritory-save-map-modal, #truterritory-save-report-modal {
  @media screen and (min-width: 1000px) {
    width: 900px;
  }

  input[type="radio"] + label {
    font-weight: 100;
  }
  input[type="radio"] + label::before {
    border: 2px solid #ccc;
  }
}
.truterritory-save-permalink-modal {
  label.checkbox[for="useCenterZoom"] {
    margin-right: 10px;
  }
  input[type="text"] {
    vertical-align: middle;
  }
  input[type="text"].link {
    float: left;
    width: 100%;
  }
  .checkbox:before {
    top: 4px;
  }
  button.btn.reset,
  button.btn.confirm,
  button.btn.cancel {
    margin: 0 0.25em;
    float: left;
  }
  button.btn.reset {
    width: 11em;
  }
  button.btn.confirm {
    width: 5.75em;
  }
  button.btn.cancel {
    width: 4.75em;
  }
  div.password {
    button.btn.apply {
      padding: 4px 15px;
      min-height: 20px;
    }
    button.btn.generate {
      background-color: transparent;
      border: 0;
      margin: 0;
      padding: 0;
      cursor: pointer;
      color: @gray;
      font-size: 16px;
      .icon-refresh();
      &:hover {
        box-shadow: none;
      }
    }
    em {
      color: @green;
    }
  }

  .controls p {
    margin: -2em 0 0 0;
    line-height: 2em;
    text-align: left;
  }
}
.save-new-workspace {
  .text-danger {
    display: block;
    font-size: 15px;
  }
}

.mapping-password .modal-dialog {
  max-width: 400px;
  .modal-body {
    text-align: center;
    padding-bottom: 1em;
  }
}

.modal-dialog .decision-options-radios {
  padding: 0 5%;

  input[type="radio"] {
    position: absolute;
  }
  input[type="radio"] + label {
    &::before {
      background-color: #ddd;
    }
    &::after {
      background-color: @grey-blue-darker;
    }
  }
  .decision-option {
    padding: 5px 0;
    label {
      font-weight: normal;
    }
  }
}

// Permalink and other buttons
#truterritory-permalink,
#truterritory-download-image .download,
#truterritory-draw {
  width: 40px;
  height: 40px;
  line-height: 40px;
  padding: 0;
  margin: 0;
  position: absolute;
  bottom: 60px;
  border-radius: 0;
  z-index: 5000;
  font-size: 19px;
  cursor: pointer;
  color: white;
  background-color: @orange-lighter;
  &[disabled] {
    background-color: lighten(@orange-lighter, 10%);
    color: darken(white, 10%);
    cursor: default;
  }
}
#truterritory-permalink {
  right: 0px;
  .icon-share;
  &:before {
    margin-left: 8px;
  }
}
#truterritory-download-image {
  > .btn,
  .selector {
    position: absolute;
    z-index: 5000;
  }
  .download {
    right: 42px;
  }
  &.bounding-box-is-visible ~ [truterritory-bookmarks], &.bounding-box-is-visible ~ .truterritory-bookmarks {
    padding-bottom: 285px;
  }
  .done {
    bottom: 60px;
    right: 37px;
    width: 82px;
    height: 40px;
    border-radius: 4px;
    cursor: pointer;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    background-color: @orange-lighter;
    color: white;
    position: absolute;
    z-index: 5001;
  }
  .selector {
    bottom: 125px;
    right: 8px;
    width: 142px;
    .dimension {
      float: left;
      width: 100%;
      color: white;
      padding-left: 45px;
      padding-right: 20px;
      label,
      span {
        position: absolute;
        line-height: 33px;
      }
      label {
        left: -4px;
      }
      input {
        float: left;
        width: 100%;
        height: 28px;
        &[read-only] {
          opacity: 0.8;
          font-style: italic;
        }
      }
      span {
        right: -3px;
      }
      .select-holder {
        float: left;
        width: 100%;
        height: 28px;
        margin-top: 2px;
        margin-left: 2px;
        border-radius: 3px;
        padding-right: 2px;
        &:after {
          line-height: 30px;
        }
        select {
          height: 22px;
          line-height: 15px;
        }
      }
    }
    .controls {
      float: left;
      width: 100%;
      text-align: center;
      margin-top: 8px;
    }
  }
}
.truterritory-report-modal, #truterritory-report-modal, #truterritory-selection-action-input-modal {
  .btn {
    margin: 0.5em;
    font-weight: 400;
  }
  .image-buttons {
    label {
      width: 40%;
      margin-left: 4%;
      padding: 1%;
      float: left;
      cursor: pointer;
      text-align: center;
      border: 1px solid transparent;
      border-radius: 5px;
      input[type="radio"] {
        visibility: visible;
        margin: 4px 0.3em;
      }
      &:has(input:checked) {
        border: 1px solid @blue;
        background-color: @grey-dark;
      }
    }
    img {
      width: 100%;
    }
  }
}
#truterritory-report-modal-radius {
  width: 300px;
  .modal-body {
    border-bottom: none;
  }
}
#truterritory-draw {
  .icon-edit;
  right: 83px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  &:before {
    margin-left: 4px;
  }
  &.enabled {
    background-color: @orange;
  }
}
.leaflet-control-legend {
  background: rgba(255, 255, 255, 0.9);
  padding-top: 30px;
  padding-left: 15px;
  position: relative;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  border-radius: 4px;
  overflow: hidden;
  width: auto;
  min-width: 100px;
  .toggle {
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
  }
  .entry {
    position: relative;
    line-height: 30px;
    height: 30px;
    .symbol {
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -5px;
      width: 10px;
      height: 10px;
      &.line-width {
        transform: rotate(45deg);
        margin-top: -5px;
        margin-left: 5px;
        .line {
          background-color: black;
          height: 10px;
        }
      }
      &.marker-fill {
        font-size: 14px;
        line-height: 10px;
        width: 14px;
        margin-left: -2px;
        .icon-map-marker;
        &::before {
          float: left;
          width: 100%;
        }
      }
    }
    .name {
      padding-left: 25px;
    }
    &.aggregate .name {
      padding-left: 0;
      padding-right: 0.7em;
      font-weight: bold;
      white-space: nowrap;
    }
    &.aggregate .value {
      font-size: 1.6em;
    }
  }
}
.leaflet-control-attribution a {
  color: #888;
}
.leaflet-control-draw-options {
  a {
    padding: 0.1px 0 0 0;
  }
  a.color span {
    width: 60%;
    height: 60%;
    margin: 20%;
    display: block;
    border-radius: 4px;
  }
  a.refresh {
    padding: 0;
    .icon-refresh();
    font-size: 22px;
    color: @gray;
    text-decoration: none;
  }
  ul.colors {
    height: 30px;
    padding: 3px 2px;
    display: block;
    background-color: rgba(145, 145, 135, 0.8);
    li {
      width: 20px;
      height: 20px;
      margin: 2px 1px;
      display: block;
      float: left;
      cursor: pointer;
      border: 1px solid rgba(255, 255, 255, 0.5);
    }
  }
}

.leaflet-bottom {
  &.leaflet-left {
    width: 100%;
  }
  [uib-datepicker] {
    // position: absolute;
    // top: -230px;
    // left: -50%;
    // background-color: white;
    position: absolute;
    top: -230px;
    left: 0;
    z-index: 1000;
    float: left;
    min-width: 260px;
    padding: 5px 0;
    margin: 2px 0 0;
    list-style: none;
    font-size: 14px;
    font-weight: 500;
    font-family: "source-sans-pro";
    background-color: #fff;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    background-clip: padding-box;
    table {
      margin: 0 auto;
    }
  }
  .slider {
    pointer-events: none;
    .ui-slider-handle,
    .ui-slider-range {
      pointer-events: auto;
    }
  }
  .leaflet-control-history-slider {
    float: left;
    width: 30%;
    clear: none;
    margin-left: 100px;
    &.one-point-slider .slider .ui-slider-handle label {
      top: -32px;
    }
    .select-holder {
      position: absolute;
      left: -90px;
      top: -50px;
      width: 100px;
    }
  }
  .leaflet-control-interval-slider {
    float: right;
    width: 30%;
    clear: none;
    margin-right: 150px;
    .slider {
      background-color: @grey-darkest;
    }
    p {
      position: absolute;
      left: -60px;
      top: -8px;
      font-weight: 700;
    }
  }
  .slider {
    float: left;
    width: 100%;
    height: 3px;
    background-color: @grey-darker;
    position: relative;
    &:before,
    &:after {
      content: " ";
      position: absolute;
      width: 2px;
      height: 15px;
      background-color: @grey-darkest;
      top: -6px;
      z-index: 7;
    }
    &:before {
      left: 0px;
    }
    &:after {
      right: 0px;
    }
    .ui-slider-range {
      position: absolute;
      width: 60%;
      background-color: @grey-darkest;
      height: 10px;
      margin-top: -3px;
      cursor: move;
    }
    .ui-slider-handle {
      border-color: #ebe9ea;
      border-radius: 50%;
      border: 8px solid;
      display: block;
      position: absolute;
      margin-top: -6px;
      margin-left: -8px;
      z-index: 8;
      cursor: pointer;
      label {
        position: absolute;
        width: auto;
        top: -12px;
        white-space: nowrap;
        background-color: @grey-darkest;
        color: white;
        padding: 2px 5px;
      }
      &:focus {
        outline: 0;
      }
    }
  }
}

.leaflet-gl-layer.maplibregl-map {
  position: absolute;
  z-index: 100;
}

#truterritory-ul-right {
  float: right;
  .btn.disabled,
  .btn:disabled {
    opacity: 1;
    background-color: @btn-default-disabled;
    color: #ddd;
    font-style: italic;
  }
  .bookmark {
    width: 20px;
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;
    &:before {
      content: "☆";
      color: white;
      line-height: 39px;
      font-size: 17px;
      cursor: pointer;
    }
    &:disabled:before {
      opacity: 0.5;
    }
    &.active:before {
      content: "★";
    }
  }
  .tiles-loading {
    line-height: 37px;
    color: white;
    .small-loading-indicator {
      margin-top: 8px;
    }
    .icon-notched-circle {
      width: 20px;
      height: 20px;
      animation: spin 0.9s linear infinite;
      transform-origin: 48.7% 50%;
      -webkit-transform-origin: 48.7% 50%;
      -ms-transform-origin: 48.7% 50%;
      line-height: 20px;
      &:before {
        width: 20px;
        height: 20px;
        font-size: 20px;
        position: absolute;
        top: 0px;
        left: 0px;
        animation: none;
      }
    }
  }

  #truterritory-save-dropdown {
    margin-right: 5px;
    button {
      margin-right: 0;
    }
    &.open {
      button {
        background-color: #f39848;
      }
      button:disabled,
      button.disabled {
        background-color: @btn-default-disabled;
      }
    }
    .dropdown-toggle {
      width: 30px;
      padding: 7px 2px;
      .icon-arrow-down;
      &:before {
        color: #fff;
        font-size: 7px;
      }
    }
    ul {
      width: 100%;
      margin: -1px 4px 0px 4px;
      background-color: #f39848;
      border-radius: 4px;
      li {
        padding: 0;
        &.divider {
          background-color: @orange-lighter;
        }
        a {
          color: white;
          font-weight: 100;
          cursor: pointer;
          &:hover {
            background-color: @orange-lighter;
          }
        }
      }
    }
  }
}

/***********************************************************************************************************************
 * Map Reports Editor
 */

#top-nav-map-report-editor-button {
  border-radius: 5px;

  &.highlight {
    color: @btn-primary-color;
    background-color: @btn-primary-bg;
  }
}

#map-reports-module {
  table {
    margin: 3px;
    width: ~"calc(100% - 6px)";
  }

  th,
  td {
    padding: 1.5px;
    text-align: center;
  }

  th {
    font-size: larger;
  }

  input[type="text"],
  input[type="select"],
  select,
  textarea {
    width: -webkit-fill-available;
  }

  input[type="checkbox"] {
    height: 20px;
    width: 20px;
    zoom: 1.05;
    visibility: inherit;
  }

  // Field Specific items
  // Fields: delete, enabled, geometry-required (geometryRequired), variables, meta, notes

  // Narrow the checkbox fields
  .report-field-delete,
  .report-field-enabled,
  .report-field-geometry-required {
    width: 30px;
  }

  textarea.report-field-variables,
  textarea.report-field-meta,
  textarea.report-field-notes {
    height: 6em; // lines / rows
  }
}

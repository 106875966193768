/**
 * Leaflet styling
 */

@import url("../../../node_modules/leaflet/dist/leaflet.css");
@import url("../../../node_modules/leaflet-areaselect/src/leaflet-areaselect.css");
@import url("../../../node_modules/leaflet-draw/dist/leaflet.draw.css");

.leaflet-marker {
  .icon-map-marker;
  color: red;
  &:before {
    width: 40px;
    height: 40px;
    font-size: 40px;
    position: relative;
    top: -20px;
    line-height: 40px;
  }
}
.leaflet-popup-content {
  font-family: @font-family-base;
  margin-right: 0px;
  margin-left: 0px;
  padding-bottom: 10px;
  .btn {
    margin-left: 0px;
    padding: 5px 10px;
    min-height: 30px;
    clear: both;
  }
  .clipboard-copy {
    position: relative;
  }
  .tooltip {
    top: -30px;
    right: -2px;
    display: block;
  }
  textarea {
    height: auto;
    padding: 0;
    margin: 0;
    margin-bottom: 3px;
    width: 100%;
  }
}
.leaflet-overlay-pane {
  z-index: 1001;
}
.leaflet-areaselect-shade {
  z-index: 1000;
}
.leaflet-areaselect-handle {
  z-index: 1001;
}
.leaflet-bottom.leaflet-left {
  z-index: 1001;
}
.leaflet-container {
  font-size: 1.25rem;
}
.truterritory-report-radius-input {
  font-family: @font-family-base;
}
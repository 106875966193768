/**
 * Data
 */

#top-nav-secondary {
  li button.archive,
  li button.unarchive {
    margin-top: 2px;
    margin-left: 8px;
    width: 23px;
    &:before {
      font-size: 23px;
    }
  }
  .upload {
    .bar {
      width: 250px;
    }
  }
  .uploader .qq-total-progress-bar-container {
    display: none;
  }
  .feedback {
    padding-top: 6px;
  }
}
#data {
  .make-md-column(12);
  padding: 0px;
  float: left;
  position: relative;
  .edit-item {
    .select-holder {
      width: 50%;
      margin-left: 18px;
      border-radius: 3px;
    }
    .date-column {
      .make-md-column(4);
      padding: 0px;
    }
    .start-date {
      .select-holder {
        display: none;
        margin-left: 0px;
        width: 100%;
      }
      input[type="text"] {
        display: none;
      }
    }
    hr {
      background-color: white;
      height: 4px;
      width: 95%;
    }
    textarea {
      display: block;
      width: 100%;
    }
    .controls {
      margin-top: 10px;
      float: left;
    }
  }

  // Data - Group/Imports
  #data-collections-wrap {
    float: left;
    width: 50%;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    overflow-y: scroll;
    height: 100%;
    padding-right: 45px;
  }
  #data-collections {
    .data-table;
    tr {
      height: 45px;
      &.active {
        background-color: lighten(@orange, 30%);
      }
      &.archived {
        background-color: lighten(@grey-dark, 3%);
        font-style: italic;
      }
      td:first-child {
        padding-left: 30px;
        position: relative;
        font-size: 15px;
      }
      td {
        &:before {
          position: absolute;
          top: 0;
          left: 0;
          content: " ";
          line-height: 40px;
          margin-left: 11px;
          font-size: 12px;
        }
        &.point,
        &.multipoint {
          .icon-map-marker;
        }
        &.polygon,
        &.multipolygon {
          .icon-truterritory-polygon;
        }
        &.linestring,
        &.multilinestring {
          .icon-truterritory-lines;
        }
        &.geometry {
          .icon-truterritory-geometry;
        }
        &.writable {
          .icon-edit;
        }
        &.data {
          .icon-data;
        }
      }
      td.type {
        font-size: 11px;
      }
    }
  }
  .right-wrap .edit-email {
    .name,
    .subject {
      display: block;
      width: 75%;
    }
  }
  .edit-email .redactor-box {
    margin: 5px;
  }
  #data-emails-wrap {
    .make-md-column(12);
    overflow-y: scroll;
    height: 100%;
  }
  #data-emails {
    float: left;
    .data-table;
    .make-md-column(12);
    table-layout: fixed;
    margin-left: 0px;
    tr.active {
      .background-glow(@grey-darker, lighten(@green, 15%), inherit, white);
      .controls .btn {
        .text-glow(@grey-darker, white);
      }
    }
    td.name {
      max-width: 75%;
      .text-truncate();
      float: left;
      line-height: 62px;
    }
    td.controls {
      width: 25px;
      padding: 0px;
      vertical-align: middle;
      .btn {
        height: 30px;
        margin-left: 0px;
        margin-right: 7px;
      }
      &:last-child {
        padding-right: 10px;
      }
    }
  }
  #collection-create {
    .form-group {
      width: 100%;
      padding-left: 5px;
      .btn {
        margin: 0;
      }
    }
    .controls {
      margin-top: 10px;
      float: right;
      .btn {
        display: inline-block;
      }
    }
    .text-danger {
      float: left;
      width: 100%;
    }
    fieldset {
      margin-bottom: 5px;
      position: relative;
      padding: 0px 5px 7px 5px;
    }
    .filenames {
      padding: 0px 5px;
      width: auto;
      float: left;
      display: block;
      line-height: 15px;
      p {
        margin: 0 0 2px 0;
      }
      .show-more {
        cursor: pointer;
        text-align: right;
        font-style: italic;
        position: absolute;
        right: 0px;
        bottom: -2px;
        background-color: white;
        padding: 3px;
      }
    }
    .clear-files {
      position: absolute;
      top: 13px;
      right: 5px;
    }
    .file-controls {
      float: left;
      width: 100%;
      .btn {
        float: left;
      }
    }
    .sql-editor {
      width: 100%;
      position: relative;
      .btn:not(.fullscreen) {
        display: inline-block;
        margin-top: 10px;
      }
      .errors {
        .text-danger;
        float: left;
        width: 100%;
      }
    }
    .sql-explain {
      max-height: 10em;
      overflow-y: auto;
      overflow-x: hidden;
      textarea {
        resize: none;
        width: 100%;
        height: 9em;
      }
    }
    .CodeMirror {
      width: 100%;
    }
    .indexes {
      float: left;
      width: 100%;
      .create {
        float: left;
        width: 100%;
        .order {
          width: 50px;
        }
      }
    }
    div.add-property {
      cursor: pointer;
      .btn {
        float: left;
        margin-right: 0.3em;
      }
      span {
        float: left;
        line-height: 26px;
      }
    }
    section.user-defined-properties {
      input, .select-holder, button {
        vertical-align: top;
      }
      .select-holder {
        float: none;
        display: inline-block;
      }
      .property {
        margin-bottom: 10px;
      }
      .property-options {
        display: inline-block;
      }
      label.checkbox {
        padding-top: 0;
      }
    }
  }
}
.indexes-creator {
  float: left;
  width: 100%;
  margin-bottom: 15px;
  .index-creator {
    float: left;
    width: 100%;
    margin-bottom: 6px;
    position: relative;
    .sortable-ul {
      padding: 0px;
      .sortable-li {
        list-style-type: none;
      }
    }
    .del {
      position: absolute;
      top: 2px;
      margin-left: 3px;
    }
  }
  .create .btn {
    margin: 0;
  }
  input[type="checkbox"]._checked + label.checkbox:after {
    top: 5px;
  }
  .multiselect {
    .dropdown {
      width: 200px;
    }
    .toggle {
      .text-truncate;
    }
    .option {
      float: none;
      position: relative;
      .checkbox {
        padding-left: 40px;
        &:before {
          left: 21px;
          top: 4px;
        }
      }
      ._checked + label.checkbox:after {
        left: 23px;
        top: 0px;
      }
    }
    label {
      .text-truncate;
      float: left;
      width: 100%;
      padding-top: 0px;
      padding-right: 55px;
      height: 25px;
      line-height: 25px;
      z-index: 1;
    }
    .draggable {
      position: absolute;
      left: 0;
      top: -1px;
      z-index: 2;
    }
    .select-holder {
      position: absolute;
      right: 5px;
      top: 2px;
      width: 55px;
      height: 22px;
      line-height: 12px;
      z-index: 2;
      &:after {
        line-height: 20px;
      }
      select {
        padding: 2px;
        margin: 0px;
      }
    }
  }
  .create,
  .column {
    float: left;
    width: 100%;
  }
  h5 {
    float: left;
    span {
      float: left;
    }
    .del {
      margin-top: -2px;
      margin-left: 7px;
    }
    .checkbox {
      padding-top: 0px;
    }
  }
  .index {
    float: left;
    width: 100%;
    padding-left: 15px;
  }
  .column {
    .btn,
    .del {
      float: right;
    }
    .del {
      height: 35px;
      line-height: 35px;
    }
  }
  .order {
    width: 75px;
  }
  .index-column-selector {
    float: left;
    clear: both;
  }
  .controls {
    margin-top: 10px;
  }
}

.collection-search-type {
  color: @grey-text;
}

epi-data-console > * {
  width: 100%;

  th {
    background-color: @brand-primary;
    color: white;
  }
}

/**
 * For all the public styling
 */
.public-body {
  overflow: hidden;
}
#page.public {
  padding: 0px;
}
#public-content {
  float: left;
  width: 100%;
  height: 100%;
  margin: 0px;

  /**
   * Public Maps
   */
  #truterritory {
    width: 100%;
    height: 100%;
    .public-controls {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      .map-name {
        position: absolute;
        left: 245px;
        top: 20px;
        color: black;
        z-index: 2;
        background-color: rgba(255, 255, 255, 0.7);
        padding: 5px 20px;
        font-weight: normal;
      }
      .location-search input {
        width: 185px;
      }
      .current-location {
        z-index: 999;
        position: absolute;
        top: 25px;
        left: 213px;
      }
    }
    .progress-indicator .wrapper {
      left: 0px;
      margin-left: 0px;
    }
    .location-search {
      z-index: 990;
      position: absolute;
      top: 17px;
      left: 55px;
    }

    .right-wrap {
      float: right;
      height: 100%;
    }
    .map-holder {
      padding: 0;
    }
    .open-triangle {
      top: 204px;
    }
    #side-menu {
      position: relative;
      top: 0px;
    }
  }

  /**
   * Public Survey Reports
   */
  #survey-reports {
    margin-left: 10%;
    margin-right: 10%;
    margin-top: -55px;
    .report-header {
      padding-left: 0px;
      h5 {
        background-color: white;
        font-size: @font-size-h1;
      }
      > .btn {
        display: none;
      }
    }
    .prebuilt-report-item {
      display: block;
      float: left;
    }
    > ul {
      float: left;
      width: 100%;
      overflow: visible;
    }
  }
}

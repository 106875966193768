@media (max-width: 1024px) {
  // Dashboard/home page
  #page > #content > div.company-list {
    padding-right: 5px;
    > .company-header > .company-header-holder {
      &:first-child {
        margin-right: 0px;
        width: 175px;
      }
      &:last-child {
        margin-right: 10px;
        width: 140px;
      }
      > .kpi,
      > .initiatives {
        > .light {
          width: 35px;
        }
      }
    }
    .company-row {
      > .kpi > .light.expired {
        margin-right: 10px;
      }
      > .initiatives > .light.expired {
        margin-right: 0px;
      }
      > .kpi,
      > .initiatives {
        > .light {
          width: 35px;
        }
      }
      > .company-content {
        padding-right: 0px;
      }
    }
  }
}
@media (max-width: @screen-md) {
  //992px
  // Dashboard/home page
  #page #content > div.company-list {
    > .company-header > .company-header-holder {
      &:first-child {
        width: 125px;
      }
      &:last-child {
        width: 100px;
        margin-right: 5px;
      }
      > .kpi,
      > .initiatives {
        > .light {
          width: 25px;
        }
      }
    }
    .company-row {
      > .kpi,
      > .initiatives {
        > .light {
          width: 25px;
        }
      }
      > .kpi > .light.expired {
        margin-right: 5px;
      }
    }
  }
  #public-content {
    /**
     * Public TruTerritory
     */
    #truterritory {
      .progress-indicator {
        .holder {
          position: relative;
          float: left;
          width: 100%;
          margin: 0px;
          top: 35%;
          left: 0px;
        }
      }
      .current-location {
        top: 75px;
        left: 16px;
      }
      #side-menu {
        .filters-holder {
          float: left;
          width: 100%;
        }
        .collections-tab {
          padding-left: 10px;
        }
        .filter-creator {
          input[type="text"] {
            margin-left: 0px;
            width: 100%;
          }
          .add {
            right: 20px;
          }
          .del {
          }
        }
        #truterritory-collection-list {
          padding: 0px;
        }
      }
    }

    /**
     * Public survey reports
     */

    #survey-reports {
      margin: 0px;
      ul {
        margin: 0px;
      }
    }
  }
}

/**
 * General mobile (iPhone sized) styling
 **/

@media (max-width: @screen-sm) {
  //768px
  [class*="col-"] {
    padding: 0px;
  }
  nav#top-nav {
    display: none;
  }
  #page {
    margin: 0px;
    padding-top: 0px;
    nav#side-nav-holder {
      position: relative;
      margin-top: 0px;
      width: 100%;
      height: 90px;
      > nav#side-nav-left {
        width: 100%;
        height: 90px;
        box-shadow: 0.4px 0 20px transparent;
        li {
          float: left;
          border: none;
          border-left: 1px solid @blue;
          width: 20%;
          &:last-child {
            border-left: 1px solid @blue;
            border-bottom: none;
          }
          > a {
            width: 100%;
            height: 90px;
            padding: 0px;
            padding-top: 17px;
            text-align: center;
            &:before {
              position: absolute;
              margin-top: -21px;
              top: 50%;
              left: 50%;
              margin-left: -17.5px;
            }
            > span {
              display: inline-block;
              text-transform: uppercase;
              font-weight: 100;
              line-height: 90px;
              margin-top: 2px;
            }
          }
          &.dash a:before {
            .dash-mobile;
          }
          &.lists a:before {
            .lists-mobile;
          }
          &.data a:before {
            .data-mobile;
          }
          &.tools a:before {
            .tools-mobile;
          }
          &.active,
          &:hover {
            > a {
              padding-right: 0px;
              margin-right: -2px;
              border-left: none;
              border-bottom: 10px solid @blue-lighter;
              &:before {
                margin-top: -16px;
                left: 50%;
                top: 50%;
              }
              &:after {
                content: " ";
                width: 20px;
                height: 7px;
                position: absolute;
                margin-top: 50px;
                margin-left: -7px;
                top: 50%;
                left: 50%;
                border-top: 7px solid @blue-lighter;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
              }
              > span {
              }
            }
          }
          &.menu {
            display: inline-block;
            &.active,
            &:hover {
              > a:before {
              }
            }
            > a:before {
              background: transparent url("../assets/assets/_/img/mobile-icons.png");
              width: 57px;
              height: 42px;
              margin-left: -28.5px;
              background-position: -1px 0px;
            }
          }
        }
      }
      > #side-nav-right {
        width: 100%;
        margin-top: -10px;
        height: auto;
        float: left;
        display: none;
      }
    }
    nav#top-nav-secondary {
      margin-left: 0px;
      padding: 8px;
      width: 100%;
      display: block;
      position: relative;
      height: 36px;
      h1,
      h2,
      h3,
      h4,
      h5 {
        margin: 0px;
        background-color: white;
        font-size: 18px;
        color: black;
      }
      > ul {
        &.navbar-nav {
          margin: 0px;
          float: left;
        }
        &.nav.navbar-nav.right {
          float: right;
          margin-right: 0px;
        }
        > li {
          margin: 0px;
          padding: 0px;
          float: left;
          &.select-holder {
            margin: 0px !important;
            > select {
              height: 34px;
            }
          }
          input {
            margin: 0px;
          }
          > button {
            width: 100%;
            margin: 0px;
            border-radius: 0px;
            padding: 9px 15px;
          }
        }
      }
    }
    > div#top-nav-slider {
      padding: 0px;
      margin: 0px;
      width: 100% !important;
      box-shadow: none;
      > .collapse-triangle {
        display: none;
      }
    }
    > #content {
      margin: 0px;
      padding: 0px;
      width: 100%;
      overflow-x: scroll;
    }
  } // Closes #page

  /**
   * Page specific styling
   **/
  #page {
    /**
     * List Manager
     **/
    > nav#top-nav-secondary {
      > ul#list-manager-nav {
        width: 100%;
        > li {
          width: 17.5%;
          &#tag-chooser,
          &#list-search {
            display: none;
          }
          &#list-select-current-list {
            width: 30%;
            padding-left: 32px;
            background-position-x: 105%;
            height: 36px;
            > select {
              margin-left: -36px;
            }
          }
          > button {
            padding-left: 0px;
            padding-right: 0px;
            text-align: center;
            border-right: 1px solid @orange-darker;
            height: 36px;
          }
          &:last-child {
            > button {
              border-right: 0px solid black;
            }
          }
        }
      }
    }
    div#top-nav-slider {
      > #edit-tags {
        margin-bottom: 0px;
        #edit-tags-public {
          border-right: 0px solid white;
        }
        #edit-tags-public,
        #edit-tags-private {
          .clearfix;
        }
        h4,
        input,
        span.tag,
        p {
          float: left;
        }
        h4 {
          margin-bottom: 0px;
          float: left;
        }
        input {
          margin-left: 4px;
        }
        p {
          display: inline-block;
          margin-bottom: 0px;
          width: 100%;
        }
        .tags {
          > .tag {
            margin-right: 3px;
            text-align: center;
            width: 95px;
          }
          .tag:first-of-type {
            .clearfix;
          }
        }
        .tag-circles {
          display: inline-block;
          width: 100% !important;
          padding: 0px;
          margin-top: 0px;
          margin-bottom: 15px;
          .circle {
            margin: 4px;
          }
        }
        .controls {
          width: 105%;
          float: left !important;
          margin-left: -15px;
          > button {
            border-radius: 0px;
            width: 50%;
            margin: 0px;
            float: left;
            height: 50px;
            font-size: 24px;
            font-weight: 400;
            box-shadow: none;
            &:first-child {
              background-color: @orange-lighter;
              color: white;
            }
            &:last-child {
              background-color: @blue3;
            }
          }
        }
      }
    }

    /**
     * Dashboard/home page
     **/

    #content > div.company-list {
      padding: 0px;
      margin: 0px;
      margin-top: 40px;
      .company-row,
      .company-header,
      .company-row > .company-content > .company-row {
        .initiatives {
          display: none;
        }
      }
      .company-header {
        > .company-header-holder {
          margin-right: 0px !important;
          > h4 {
            height: 38px;
            width: 50% !important;
            line-height: 38px;
            background-color: @blue3-darkest;
            color: white;
            text-align: center;
            position: absolute;
            top: -26px;
            float: left;
            font-size: 16px;
            cursor: pointer;
            &.active {
              background-color: @blue3;
            }
          }
          &:first-child > h4 {
            left: 50%;
            margin-left: 1px;
          }
          &:last-child > h4 {
            left: -1px;
          }
          > .kpi,
          > .initiatives {
            float: right;
            > .light {
              width: 35px;
            }
          }
          > .initiatives {
            width: 175px;
          }
          > .kpi {
            width: 140px;
          }
        }
      }
      .company-row {
        min-height: 50px;
        &:before {
          left: 10px;
        }
        > .company {
          max-width: 130px;
          padding-left: 40px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 14px;
          &:before {
            left: 30px;
          }
        }
        > .kpi,
        > .initiatives {
          .light {
            width: 35px;
            &.expired {
              margin-right: 0px !important;
            }
          }
        }
        > .company-content {
          padding: 0px;
          .company {
            max-width: 40%;
          }
          > .company-row {
            &:last-child {
              border-bottom: 10px solid @grey-darker;
            }
            > .company {
              padding-left: 20px;
            }
            > .company-sub-row {
              width: 100%;
              padding-left: 18.5px;
              > .company-row {
                .company {
                  &:before {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }

    /**
     * TruTerritory
     **/
    #top-nav-slider {
      #truterritory-workspace-module {
        > div:first-child {
          #truterritory-workspace-selector-dropdown {
            width: 100%;
          }
        }
      }
    }
    #truterritory {
      #top-nav-secondary {
        > ul {
          > li.search {
            width: 50%;
            > input {
              width: 100%;
            }
          }
          .btn.location {
            margin-left: 5px;
          }
        }
      }
    }
    #truterritory-permalink {
      left: 15px;
    }
    #truterritory-ul-right {
      position: absolute;
      bottom: 0;
      top: auto;
      right: 15px;
    }
  } //#page

  #public-content #truterritory {
    #side-menu {
      width: 300px;
    }
  }
} // Closes media query for (max-width: @screen-sm-min)

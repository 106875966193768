@import url("../../../node_modules/codemirror/lib/codemirror.css");
@import url("../../../node_modules/codemirror/theme/neat.css");
@import url("../../../node_modules/codemirror/addon/hint/show-hint.css");
@import url("../../../node_modules/codemirror/addon/lint/lint.css");
@import url("../../../node_modules/codemirror/addon/display/fullscreen.css");

.cm-s-epiphany span.cm-keyword {
  color: @blue-darker;
  font-weight: 400;
}
.cm-s-epiphany span.cm-number,
.cm-s-epiphany span.cm-atom {
  color: #439160;
  font-weight: 400;
}
.CodeMirror-fullscreen {
  z-index: 10000 !important;
}
.CodeMirror {
  .redactor-btn-image {
    position: absolute;
    right: 2px;
    top: 2px;
    cursor: pointer;
    z-index: 1000;
  }
  .CodeMirror-widget {
    .box {
      width: 15px;
      height: 15px;
      cursor: pointer;
      display: inline-block;
      margin-bottom: -3px;
      margin-right: 4px;
    }
    .color-box {
      border: 1px solid grey;
    }
    .image-preview {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      &:hover:not(.empty) {
        width: 50px;
        height: 50px;
        margin-top: -25px;
        margin-left: -18px;
      }
      &.empty {
        .icon-upload;
        color: @grey-darkest;
        line-height: 14px;
      }
    }
  }
  fine-uploader, .fine-uploader {
    position: absolute;
    z-index: 100;
    font-family: @font-family-base;
    height: 100%;
    overflow: scroll;
  }
  .error-background {
    background-color: lighten(@red, 25%);
  }
  .CodeMirror-lint-marker-warning {
    width: 16px;
    height: 16px;
    display: inline-block;
  }
}
.error-tooltip {
  position: absolute;
  pointer-events: none;
  z-index: 100000;
  background-color: #ffd;
  border: 1px solid black;
  border-radius: 4px 4px 4px 4px;
  color: black;
  font-family: monospace;
  font-size: 10pt;
  overflow: hidden;
  padding: 2px 5px;
  white-space: pre;
  white-space: pre-wrap;
  max-width: 600px;
}

@font-face {
  font-family: "icons-1507148234";
  src: url("../assets/assets/_/fonts/icons-1507148234.eot"); /* IE9 Compat Modes */
  src: url("../assets/assets/_/fonts/icons-1507148234.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */ url("../assets/assets/_/fonts/icons-1507148234.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../assets/assets/_/fonts/icons-1507148234.woff") format("woff"),
    /* Pretty Modern Browsers */ url("../assets/assets/_/fonts/icons-1507148234.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../assets/assets/_/fonts/icons-1507148234.svg#icons-1507148234") format("svg"); /* Legacy iOS */
}

.icon-char(@filename) {
  @archive: "\E001";
  @arrow-down: "\E002";
  @arrow-left: "\E003";
  @arrow-right: "\E004";
  @arrow-up: "\E005";
  @checkmark-circle: "\E006";
  @checkmark-no-circle: "\E007";
  @checkmark: "\E008";
  @close: "\E009";
  @company: "\E00A";
  @copy: "\E00B";
  @data: "\E00C";
  @delete-2: "\E00D";
  @delete-3: "\E00E";
  @delete: "\E00F";
  @disabled: "\E010";
  @download: "\E011";
  @draggable: "\E012";
  @edit: "\E013";
  @expand: "\E014";
  @folder: "\E015";
  @indicator-arrow-down: "\E016";
  @indicator-arrow-up: "\E017";
  @information: "\E018";
  @lines: "\E019";
  @link: "\E01A";
  @location: "\E01B";
  @map-marker: "\E01C";
  @menu-dashboard: "\E01D";
  @menu-data: "\E01E";
  @menu-lists: "\E01F";
  @menu-tools: "\E020";
  @menu-truterritory: "\E021";
  @menubar-mobile: "\E022";
  @notched-circle: "\E023";
  @open: "\E024";
  @plus-2: "\E025";
  @plus: "\E026";
  @points: "\E027";
  @refresh: "\E028";
  @rotate: "\E029";
  @schedule: "\E02A";
  @scissors: "\E02B";
  @search: "\E02C";
  @settings: "\E02D";
  @share: "\E02E";
  @truterritory-geometry: "\E02F";
  @truterritory-lines: "\E030";
  @truterritory-polygon: "\E031";
  @unarchive: "\E032";
  @upload: "\E033";
  @view: "\E034";

  content: @@filename;
}

.icon-font(@filename, @insert: before) {
  @pseudo-selector: ~":@{insert}";

  &@{pseudo-selector} {
    font-family: "icons-1507148234";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    // speak: none; // only necessary if not using the private unicode range (firstGlyph option)
    text-decoration: none;
    text-transform: none;
    .icon-char(@filename);
  }
}

.icon-archive {
  .icon-font(archive);
}
.icon-arrow-down {
  .icon-font(arrow-down);
}
.icon-arrow-left {
  .icon-font(arrow-left);
}
.icon-arrow-right {
  .icon-font(arrow-right);
}
.icon-arrow-up {
  .icon-font(arrow-up);
}
.icon-checkmark-circle {
  .icon-font(checkmark-circle);
}
.icon-checkmark-no-circle {
  .icon-font(checkmark-no-circle);
}
.icon-checkmark {
  .icon-font(checkmark);
}
.icon-close {
  .icon-font(close);
}
.icon-company {
  .icon-font(company);
}
.icon-copy {
  .icon-font(copy);
}
.icon-data {
  .icon-font(data);
}
.icon-delete-2 {
  .icon-font(delete-2);
}
.icon-delete-3 {
  .icon-font(delete-3);
}
.icon-delete {
  .icon-font(delete);
}
.icon-disabled {
  .icon-font(disabled);
}
.icon-download {
  .icon-font(download);
}
.icon-draggable {
  .icon-font(draggable);
}
.icon-edit {
  .icon-font(edit);
}
.icon-expand {
  .icon-font(expand);
}
.icon-folder {
  .icon-font(folder);
}
.icon-indicator-arrow-down {
  .icon-font(indicator-arrow-down);
}
.icon-indicator-arrow-up {
  .icon-font(indicator-arrow-up);
}
.icon-information {
  .icon-font(information);
}
.icon-lines {
  .icon-font(lines);
}
.icon-link {
  .icon-font(link);
}
.icon-location {
  .icon-font(location);
}
.icon-map-marker {
  .icon-font(map-marker);
}
.icon-menu-dashboard {
  .icon-font(menu-dashboard);
}
.icon-menu-data {
  .icon-font(menu-data);
}
.icon-menu-lists {
  .icon-font(menu-lists);
}
.icon-menu-tools {
  .icon-font(menu-tools);
}
.icon-menu-truterritory {
  .icon-font(menu-truterritory);
}
.icon-menubar-mobile {
  .icon-font(menubar-mobile);
}
.icon-notched-circle {
  .icon-font(notched-circle);
}
.icon-open {
  .icon-font(open);
}
.icon-plus-2 {
  .icon-font(plus-2);
}
.icon-plus {
  .icon-font(plus);
}
.icon-points {
  .icon-font(points);
}
.icon-refresh {
  .icon-font(refresh);
}
.icon-rotate {
  .icon-font(rotate);
}
.icon-schedule {
  .icon-font(schedule);
}
.icon-scissors {
  .icon-font(scissors);
}
.icon-search {
  .icon-font(search);
}
.icon-settings {
  .icon-font(settings);
}
.icon-share {
  .icon-font(share);
}
.icon-truterritory-geometry {
  .icon-font(truterritory-geometry);
}
.icon-truterritory-lines {
  .icon-font(truterritory-lines);
}
.icon-truterritory-polygon {
  .icon-font(truterritory-polygon);
}
.icon-unarchive {
  .icon-font(unarchive);
}
.icon-upload {
  .icon-font(upload);
}
.icon-view {
  .icon-font(view);
}

.icon-archive-after {
  .icon-font(archive, "after");
}
.icon-arrow-down-after {
  .icon-font(arrow-down, "after");
}
.icon-arrow-left-after {
  .icon-font(arrow-left, "after");
}
.icon-arrow-right-after {
  .icon-font(arrow-right, "after");
}
.icon-arrow-up-after {
  .icon-font(arrow-up, "after");
}
.icon-checkmark-circle-after {
  .icon-font(checkmark-circle, "after");
}
.icon-checkmark-no-circle-after {
  .icon-font(checkmark-no-circle, "after");
}
.icon-checkmark-after {
  .icon-font(checkmark, "after");
}
.icon-close-after {
  .icon-font(close, "after");
}
.icon-company-after {
  .icon-font(company, "after");
}
.icon-copy-after {
  .icon-font(copy, "after");
}
.icon-data-after {
  .icon-font(data, "after");
}
.icon-delete-2-after {
  .icon-font(delete-2, "after");
}
.icon-delete-3-after {
  .icon-font(delete-3, "after");
}
.icon-delete-after {
  .icon-font(delete, "after");
}
.icon-disabled-after {
  .icon-font(disabled, "after");
}
.icon-download-after {
  .icon-font(download, "after");
}
.icon-draggable-after {
  .icon-font(draggable, "after");
}
.icon-edit-after {
  .icon-font(edit, "after");
}
.icon-expand-after {
  .icon-font(expand, "after");
}
.icon-folder-after {
  .icon-font(folder, "after");
}
.icon-indicator-arrow-down-after {
  .icon-font(indicator-arrow-down, "after");
}
.icon-indicator-arrow-up-after {
  .icon-font(indicator-arrow-up, "after");
}
.icon-information-after {
  .icon-font(information, "after");
}
.icon-lines-after {
  .icon-font(lines, "after");
}
.icon-link-after {
  .icon-font(link, "after");
}
.icon-location-after {
  .icon-font(location, "after");
}
.icon-map-marker-after {
  .icon-font(map-marker, "after");
}
.icon-menu-dashboard-after {
  .icon-font(menu-dashboard, "after");
}
.icon-menu-data-after {
  .icon-font(menu-data, "after");
}
.icon-menu-lists-after {
  .icon-font(menu-lists, "after");
}
.icon-menu-tools-after {
  .icon-font(menu-tools, "after");
}
.icon-menu-truterritory-after {
  .icon-font(menu-truterritory, "after");
}
.icon-menubar-mobile-after {
  .icon-font(menubar-mobile, "after");
}
.icon-notched-circle-after {
  .icon-font(notched-circle, "after");
}
.icon-open-after {
  .icon-font(open, "after");
}
.icon-plus-2-after {
  .icon-font(plus-2, "after");
}
.icon-plus-after {
  .icon-font(plus, "after");
}
.icon-points-after {
  .icon-font(points, "after");
}
.icon-refresh-after {
  .icon-font(refresh, "after");
}
.icon-rotate-after {
  .icon-font(rotate, "after");
}
.icon-schedule-after {
  .icon-font(schedule, "after");
}
.icon-scissors-after {
  .icon-font(scissors, "after");
}
.icon-search-after {
  .icon-font(search, "after");
}
.icon-settings-after {
  .icon-font(settings, "after");
}
.icon-share-after {
  .icon-font(share, "after");
}
.icon-truterritory-geometry-after {
  .icon-font(truterritory-geometry, "after");
}
.icon-truterritory-lines-after {
  .icon-font(truterritory-lines, "after");
}
.icon-truterritory-polygon-after {
  .icon-font(truterritory-polygon, "after");
}
.icon-unarchive-after {
  .icon-font(unarchive, "after");
}
.icon-upload-after {
  .icon-font(upload, "after");
}
.icon-view-after {
  .icon-font(view, "after");
}

/**
 * Home page
 */

#content > .company-list {
  .transition {
    -webkit-transition: all ease 0.5s;
    -moz-transition: all ease 0.5s;
    -o-transition: all ease 0.5s;
    transition: all ease 0.5s;
  }

  padding: 35px 0px 15px 15px;
  float: left;
  width: 100%;
  color: @grey-darkest;
  // Triangle
  .company-triangle() {
    content: " ";
    position: absolute;
    top: 15px;
    left: 20px;
    width: 0;
    height: 0;
    border-bottom: 5px solid transparent;
    border-top: 5px solid transparent;
    border-left: 9px solid black;
    display: block;
  }
  .company-triangle-inactive() {
    .company-triangle;
    border-left: 9px solid @grey-darkest;
  }
  .company-triangle-open() {
    border-top: 9px solid black;
    border-bottom: 0px solid transparent;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
  }
  // Active light colors
  .active-lights () {
    .kpi,
    .initiatives {
      .light {
        color: white;
        overflow: hidden;
        .no-select;
        &.completed {
          background-color: #7ab2d4;
          color: #7ab2d4;
        }
        &.positive {
          background-color: #7fbb93;
          color: #7fbb93;
        }
        &.caution {
          background-color: #f7c02a;
          color: #f7c02a;
        }
        &.negative {
          background-color: #bb4643;
          color: #bb4643;
        }
        &.expired {
          background-color: #3f3f3f;
          color: #3f3f3f;
        }
      }
      &:hover .light {
        .transition;
        &.completed,
        &.positive,
        &.caution,
        &.negative {
          color: black;
        }
        &.expired {
          color: white;
        }
      }
    }
  }
  // Inactive light colors
  .inactive-lights() {
    .kpi,
    .initiatives {
      .light {
        .transition;
        overflow: hidden;
        &.completed {
          background-color: #7ab2d4;
          color: #7ab2d4;
        }
        &.positive {
          background-color: #e2f0d5;
          color: #e2f0d5;
        }
        &.caution {
          background-color: #f7ead9;
          color: #f7ead9;
        }
        &.negative {
          background-color: #f1d5d5;
          color: #f1d5d5;
        }
        &.expired {
          background-color: #cfcfcf;
          color: #cfcfcf;
        }
      }
    }
  }

  // Here's where the actual styling starts
  // First, style the row & the header exactly the same so they match
  .company-row {
    border: 1px solid white;
    float: left;
    width: 100%;
    position: relative;
    .company {
      float: left;
    }
    .company {
      height: 42px;
      line-height: 42px;
      padding-left: 10px;
      margin: 0px;
      display: inline;
      font-size: 16px;
      cursor: pointer;
      > a {
        color: black;
        &:hover {
          text-decoration: none;
        }
      }
      &:before {
        content: " ";
        width: 4px;
        height: 42px;
        background-color: @orange;
        position: absolute;
        top: 0px;
        left: 46px;
        .transition;
      }
    }
    .triangle {
      width: 50px;
      height: 42px;
      float: left;
    }
    border: 1px solid @grey-lightest;
    border-bottom: none;
    &:last-child {
      border-bottom: 1px solid @grey-lightest;
    }
    .company {
      color: black;
    }
    > .company-content {
      border-top: 3px solid #babcc0;
      border-bottom: 1px solid white;
      cursor: auto;
      padding: 30px 0px 41px 0px;
      float: left;
      background-color: @grey-darker;
      width: 100%;
      color: black;
      .company-wrap .company-row {
        width: 100%;
        float: left;
        border: none;
        border-top: 10px solid @grey-darker;
        background-color: #ececec;
        padding: 0px;
        margin-left: 45px;
        position: relative;
        &:after {
          content: " ";
          position: absolute;
          right: 44px;
          top: 0px;
          background-color: @grey-darker;
          width: 51px;
          height: 42px;
        }
        .triangle {
          position: absolute;
          left: -45px;
          width: 45px;
          &:after {
            left: 20px;
          }
        }
        &.single .triangle {
          display: none;
        }
        .company {
          .transition;
          padding-left: 25px;
          &:before {
            left: 0px;
          }
          &:after {
            content: " ";
            position: absolute;
            top: 0;
            left: 4px;
            width: 9px;
            height: 42px;
            background: repeating-linear-gradient(-45deg, @grey-darker, @grey-darker 3px, @blue3 3px, @blue3 6px);
          }
        }
        .triangle:after {
          .company-triangle;
          left: -25px;
        }
        > .company-sub-row {
          display: none;
          width: 100%;
          background-color: @grey-darker;
          float: left;
          > .company-row {
            width: 100%;
            padding: 0px;
            border: none;
            background-color: #ececec;
            border-top: 10px solid @grey-darker;
            &:after {
              right: 89px;
            }
            h3:before,
            h3:after {
              display: none;
            }
          }
        }
        &.single .company:after {
          display: none;
        }
      }
    }
    &.inactive {
      .inactive-lights;
      .triangle {
        &:after {
          .company-triangle-inactive;
        }
      }
      &.single .triangle:after {
        display: none;
      }
      .company {
        color: inherit;
      }
    }
    &.active {
      .triangle {
        &:after {
          .company-triangle-open;
        }
      }
      .company {
        color: black;
        &:before {
          background-color: @blue3;
        }
      }
      .company-content {
        display: block;
      }
      &.single .triangle:after {
        display: none;
      }
      .active-lights;
      .company-wrap > .company-row {
        &.active {
          .triangle:after {
            .company-triangle-open;
            left: 20px;
          }
        }
        &.inactive {
          .triangle {
            &:after {
              -webkit-transition: border-left-color ease 0.5s;
              -moz-transition: border-left-color ease 0.5s;
              -o-transition: border-left-color ease 0.5s;
              transition: border-left-color ease 0.5s;
              .company-triangle-inactive;
            }
          }
          .company {
            color: @grey-darkest;
          }
          .inactive-lights;
          &.single .company:after {
            display: none;
          }
        }
        &.all-inactive {
          .triangle:after {
            .company-triangle;
          }
          .company {
            color: black;
          }
          .active-lights;
        }
      }
    }

    &.all-inactive {
      .active-lights;
      .triangle:after {
        .company-triangle;
      }
      .company {
        color: black;
      }
    }
  }
}
